<div class="modal-wrapper p-8">
  <div class="modal-header mb-6 d-flex justify-between">
    <div>
      <span class="mat-subtitle-1 mb-0 text-black">Feedback Received</span>
      <p class="textXS mt-2 text-black">
        What client think about the Resource?
      </p>
    </div>
    <mat-icon class="pointer" (click)="onNoClick()">close</mat-icon>
  </div>
  <div class="d-flex mb-6 medium-icon">
    <mat-icon class="rating-icon warning-text mr-4"> star </mat-icon>
    <mat-icon class="rating-icon warning-text mr-4"> star </mat-icon>
    <mat-icon class="rating-icon warning-text mr-4"> star </mat-icon>
    <mat-icon class="rating-icon warning-text mr-4"> star </mat-icon>
    <mat-icon class="rating-icon warning-text mr-4"> star </mat-icon>
  </div>
  <div class="content-wrapper my-4">
    <span class="mb-2 textS text d-block">Response</span>
    <p class="textS grey-text">
      The design content delivered exhibited a seamless blend of creativity and
      functionality, enhancing the overall user experience. The attention to
      detail and collaboration with the development team ensured a smooth
      integration, resulting in visually impressive and technically sound
      outcomes.
    </p>
  </div>
  <div class="modal-footer mt-2 d-flex justify-end">
    <sft-button
      [label]="'Close'"
      [color]="'primary'"
      [variant]="'flat'"
      [disableRipple]="false"
      [size]="'large'"
      (buttonClick)="onNoClick()"
    ></sft-button>
  </div>
</div>
